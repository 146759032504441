<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Vamos começar preenchendo as informações básicas
      </div>
      <validation-observer v-slot="{ valid }">
        <validation-helper
          :interceptor="valid"
          @is-valid="$emit('step-observer', { step: 0, complete: $event })"
        />
        <v-row class="mx-auto">
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              id="titulo-conciliacao"
            >
              <v-text-field
                class="my-0"
                v-model="titulo"
                label="Título"
                outlined
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col
            sm="3"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              id="tuc-selecionada-conciliacao"
            >
              <v-select
                class="my-0"
                v-model="tucSelecionada"
                :items="tucsList"
                label="TUC"
                outlined
                @change="setPrioridades($event)"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col
            sm="8"
            cols="11"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              id="flexibilidade-conciliacao"
            >
              <v-select
                class="my-0"
                v-model="flexibilidade"
                :items="['Flexível', 'Rígida', 'Parcial']"
                label="Flexibilidade"
                outlined
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="1"
            class="pl-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  id="dialog-flexibilidade-conciliacao"
                  class="pt-4"
                  size="28"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogFlexibilidade = true"
                >
                  mdi-progress-question
                </v-icon>
              </template>
              <span
                >Clique aqui para obter um informativo sobre as diferenças das
                flexibilidades</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col cols="12">
            <v-expansion-panels
              v-model="panels"
              accordion
              multiple
            >
              <v-expansion-panel id="conciliacao-editar-prioridades">
                <v-expansion-panel-header
                  style="font-weight: 300; font-size: 15px"
                >
                  Editar Prioridade
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <draggable
                    v-bind="dragOptions"
                    v-model="atributosList"
                    @end="setPrioridades(undefined)"
                  >
                    <v-checkbox
                      v-for="atributo in atributosList"
                      :key="atributo"
                      dense
                      multiple
                      hide-details
                      class="d-inline-flex shrink mx-1 my-0 pa-0"
                      @change="setPrioridades(undefined)"
                      v-model="prioridades"
                      :label="atributo"
                      :value="atributo"
                    />
                  </draggable>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel id="conciliacao-editar-rodadas">
                <v-expansion-panel-header
                  style="font-weight: 300; font-size: 15px"
                >
                  Editar Rodadas
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <rodadas-conciliacoes
                    v-model="rodadas"
                    :atributosList="['TUC', ...atributosList]"
                    :tipoConciliacao="tipoConciliacao"
                    :page.sync="rodadasPage"
                    actionBtnsSm="5"
                    atributosSm="2"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col
            cols="12"
            class="pl-2"
            id="conciliacao-atributos-coringa"
          >
            <div class="d-inline-flex">
              <v-checkbox
                class="my-0"
                v-model="atributosCoringa"
                label="Habilitar Atributos Coringa?"
              />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    id="conciliacao-atributos-coringa-help"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1 mb-5"
                    @click="dialogAtributosCoringa = true"
                  >
                    mdi-progress-question
                  </v-icon>
                </template>
                <span
                  >Clique aqui para obter um informativo sobre habilitar
                  atributos coringa</span
                >
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </v-tab-item>
    <template v-for="(item, key) in partidaReferencia">
      <v-tab-item
        class="pb-12"
        :key="key"
        eager
      >
        <validation-observer v-slot="{ valid }">
          <validation-helper
            :interceptor="valid"
            @is-valid="
              $emit('step-observer', {
                step: key === 'partida' ? 1 : 2,
                complete: $event
              })
            "
          />
          <div :id="`conciliacao-base-de-${key}`">
            <v-row
              align="center"
              class="mx-auto"
            >
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="item.origem"
                    :items="['ARQUIVO', 'RELATORIO']"
                    :label="
                      key == 'partida'
                        ? 'Origem Base de Partida'
                        : 'Origem Base de Referência'
                    "
                    outlined
                    @change="clearInputsOrigem(item.origem)"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="mx-auto"
            >
              <v-col
                cols="12"
                class="mb-4"
                v-show="item.origem"
              >
                <v-alert
                  dense
                  outlined
                  type="info"
                  color="blue"
                  class="pa-2"
                >
                  <div v-if="item.origem == 'RELATORIO'">
                    <template v-if="key == 'partida'">
                      O relatório selecionado deve conter os campos
                      <b>ID, ODI, TI, TUC, A1, A2, A3, A4, A5, A6</b>,
                      <b>QTD</b> e <b>DATA</b>
                    </template>
                    <template v-else>
                      O relatório selecionado deve conter os campos
                      <b>ID, ODI, TI, TUC, A1, A2, A3, A4, A5, A6</b>,
                      <b>QTD</b>, <b>DATA</b> e <b>DATA CONEXÃO</b>.
                    </template>
                    <br />Obs: O campo <b>QTD</b> pode conter os nome
                    <b>QTD Somatório</b>, <b>QTD MCPSE</b> ou <b>QTD</b> para
                    ser validado. <br />Obs²: O campo <b>ID</b> pode conter os
                    nomes <b>ID</b>, <b>COD_ID</b>, <b>IMOBILIZADO</b> ou
                    <b>SUBNÚMERO</b> para ser validado. <br />Obs³: O campo
                    <b>DATA</b> pode conter os nomes <b>DATA</b> ou
                    <b>DATA IMOBILIZAÇÃO</b> para ser validado.
                  </div>
                  <div v-if="item.origem == 'ARQUIVO'">
                    <template v-if="key == 'partida'">
                      O arquivo selecionado deve conter os campos
                      <b>ID, ODI, TI, TUC, A1, A2, A3, A4, A5, A6</b>,
                      <b>QTD</b> e <b>DATA</b>
                    </template>
                    <template v-else>
                      O arquivo selecionado deve conter os campos
                      <b>ID, ODI, TI, TUC, A1, A2, A3, A4, A5, A6</b>,
                      <b>QTD</b>, <b>DATA</b> e <b>DATA CONEXÃO</b>.
                    </template>
                    <br />Obs: O campo <b>QTD</b> pode conter os nome
                    <b>QTD Somatório</b>, <b>QTD MCPSE</b> ou <b>QTD</b> para
                    ser validado. <br />Obs²: O campo <b>ID</b> pode conter os
                    nomes <b>ID</b>, <b>COD_ID</b>, <b>IMOBILIZADO</b>,
                    <b>EI</b> ou <b>SUBNÚMERO</b> para ser validado. <br />Obs³:
                    O campo <b>DATA</b> pode conter os nomes <b>DATA</b> ou
                    <b>DATA IMOBILIZAÇÃO</b> para ser validado.
                    <link-download-arquivo-modelo
                      :tipoConciliacao="tipoConciliacao"
                    />
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="mx-auto"
              v-if="item.origem == 'RELATORIO'"
            >
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    label="Relatorio"
                    placeholder="Selecione um relatório"
                    readonly
                    outlined
                    v-model="item.relatorioNome"
                    :success="item.headerValido && item.relatorioNome != ''"
                    @focus.close="dialogRelatorio = true"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <validation-provider rules="required">
                <input
                  type="hidden"
                  :name="`valida-header-relatorio-${key}`"
                  :value="item.headerValido ? 'OK' : ''"
                />
              </validation-provider>
            </v-row>
            <v-row
              align="center"
              class="mx-auto"
              v-if="item.origem == 'ARQUIVO'"
            >
              <v-col
                cols="12"
                class="pl-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-file-input
                    @change="uploadNewFile(item)"
                    label="Arquivo CSV"
                    placeholder="Selecione um arquivo CSV"
                    outlined
                    accept=".csv, .zip"
                    v-model="item.arquivo"
                    :show-size="!item.fakeFile"
                    truncate-length="30"
                    :loading="item.uploading"
                    loader-height="4"
                    :success="
                      (item.arquivoId && item.arquivo && item.headerValido) ||
                      item.fakeFile
                        ? true
                        : false
                    "
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider rules="required">
                  <input
                    type="hidden"
                    :name="`valida-upload-${key}`"
                    :value="
                      (item.arquivoId && item.headerValido) || item.fakeFile
                        ? 'OK'
                        : ''
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <parametros-arquivo-csv
              v-if="item.origem == 'ARQUIVO'"
              :delimitador.sync="item.delimitador"
              :involucro.sync="item.involucro"
              :escape.sync="item.escape"
            />
          </div>
        </validation-observer>
      </v-tab-item>
    </template>
    <dialog-flexibilidade :activated.sync="dialogFlexibilidade" />
    <dialog-atributos-coringa :activated.sync="dialogAtributosCoringa" />
    <dialog-seletor-relatorio
      :activated.sync="dialogRelatorio"
      @selected="setRelatorio($event)"
    />
  </v-container>
</template>

<script>
import conciliacoesWizardTabsMixins from '@/mixins/conciliacoes/conciliacoesWizardTabsMixins.js';
import randomString from '@/utils/generateRandomString';

export default {
  mixins: [conciliacoesWizardTabsMixins],

  components: {
    draggable: () => import('vuedraggable'),
    RodadasConciliacoes: () =>
      import('@/components/power-ups/conciliacoes/RodadasConciliacoes.vue'),
    DialogFlexibilidade: () =>
      import('@/components/power-ups/conciliacoes/DialogFlexibilidade.vue'),
    DialogAtributosCoringa: () =>
      import('@/components/power-ups/conciliacoes/DialogAtributosCoringa.vue'),
    DialogSeletorRelatorio: () =>
      import('@/components/general/seletores/DialogSeletorRelatorio.vue'),
    ValidationHelper: () => import('@/components/general/ValidationHelper.vue'),
    ParametrosArquivoCsv: () =>
      import('@/components/general/GeneralParametrosArquivoCsv.vue'),
    LinkDownloadArquivoModelo: () =>
      import(
        '@/components/power-ups/conciliacoes/LinkDownloadArquivoModelo.vue'
      )
  },

  created() {
    if (this.$route.params.id) this.fillFields();
  },

  data: () => ({
    tipoConciliacao: 'EXPRESS',
    rodadasPage: 1
  }),

  computed: {
    dragOptions() {
      return { animation: 300, ghostClass: 'ghost' };
    }
  },

  methods: {
    setPrioridades(tucSelecionada = undefined) {
      const prioridadePorTuc = {
        125: ['ODI', 'A1', 'A2', 'A4', 'A3', 'TI'],
        135: ['ODI', 'A2', 'A4', 'A3', 'A1', 'A5'],
        160: ['ODI', 'A1', 'A2', 'A4', 'A3', 'A5', 'A6', 'TI'],
        190: ['TI', 'A3', 'A1', 'A2', 'A4', 'A5'],
        210: ['ODI', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'TI'],
        255: ['A1', 'A3', 'A2', 'A4', 'A5'],
        295: ['ODI', 'TI', 'A1', 'A5', 'A6', 'A2', 'A4', 'A3'],
        330: ['ODI', 'A1', 'A2', 'A3', 'A4'],
        340: ['ODI', 'TI', 'A2', 'A5', 'A4', 'A3', 'A1', 'A6'],
        345: ['ODI', 'A2', 'A4', 'A3', 'A5', 'A6', 'TI'],
        395: ['ODI', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6'],
        565: ['TI', 'A4', 'A5', 'A3', 'A1', 'A2', 'A6'],
        570: ['ODI', 'A2', 'A4', 'A3', 'A5', 'TI', 'A1', 'A6'],
        575: ['ODI', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6'],
        580: ['ODI', 'A4', 'A5', 'A1', 'A3', 'A2', 'A6']
      };

      if (tucSelecionada != undefined) {
        this.prioridades = ['TUC', ...prioridadePorTuc[this.tucSelecionada]]; // TUC é fixamente o primeiro na prioridade
        this.atributosList = [
          ...this.prioridades.filter((atrib) => atrib != 'TUC'),
          ...this.atributosList.filter(
            (atrib) => !this.prioridades.includes(atrib)
          )
        ];
      } else {
        this.prioridades = [
          // TUC é fixamente o primeiro na prioridade
          'TUC',
          ...this.atributosList.filter((atrib) =>
            this.prioridades.includes(atrib)
          )
        ];
      }

      this.criarRodadas(this.prioridades);
    },

    calculaPeso(rodada, prioridade) {
      let peso = rodada.length * 100;

      rodada.forEach((atrib) => {
        peso += prioridade[atrib];
      });

      return peso;
    },

    criarRodadas(listaColunas) {
      let j = 0;
      let prioridade = {};
      listaColunas.forEach((atrib) => {
        prioridade[atrib] = atrib == 'TUC' ? 0 : listaColunas.length - j;
        j++;
      });

      let aux = [];
      let tam = listaColunas.length;

      let start = 2 ** (tam - 1) - 1;
      let stop = -1;
      let step = -1;

      for (var i = start; i > stop; i += step) {
        let b = (Number(i) >>> 0).toString(2);
        b = '1'.padEnd(tam - b.length, '0') + b;

        let rodada = [];
        for (var k = 0; k < b.length; k++) {
          if (b[k] == 1) {
            rodada.push(listaColunas[k]);
          }
        }

        let rodadaPeso = {
          rodada: rodada,
          peso: -this.calculaPeso(rodada, prioridade)
        };

        aux.push(rodadaPeso);
      }

      aux.sort((val1, val2) => {
        if (val1.peso > val2.peso) return 1;
        else if (val1.peso == val2.peso) return 0;
        return -1;
      });

      /* Volta a exibir no max. 10 rodadas antes de gerar as novas rodadas */
      this.rodadasPage = 1;

      this.rodadas = aux.map((aux) => {
        return { valores: aux.rodada, key: randomString(20) };
      });
    }
  },
  watch: {
    panels(newVal, oldVal) {
      /* Caso o usuario feche o painel 'Editar Rodadas' a paginação volta a exibir 10 elementos no max. */
      if (newVal != 1 && oldVal == 1) {
        this.rodadasPage = 1;
      }
    }
  }
};
</script>
